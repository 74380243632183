'use client'

import { Link } from '@remix-run/react'
import HeaderNavMenu from './HeaderNavMenu.tsx'

export default function Header() {
	return (
		<header className="top-0 z-40 w-full border-b bg-background">
			<div className="container flex h-16 items-center space-x-4 sm:justify-between sm:space-x-0">
				<div className="hidden gap-6 md:flex md:gap-10">
					<Link
						to="/"
						aria-label="papelea"
						title="papelea"
						className="items-center space-x-2 md:flex"
					>
						<img
							className="h-12 w-auto sm:w-20 md:w-28 lg:w-32"
							src="/img/brand/logo.png"
							alt="Papelea logo"
						/>

						<span className="ml-3 text-2xl font-bold tracking-wide text-gray-900">
							{' '}
						</span>
					</Link>
				</div>
				<HeaderNavMenu />
			</div>
		</header>
	)
}
