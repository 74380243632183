'use client'

import { Link } from '@remix-run/react'
import React from 'react'
import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuLink,
	NavigationMenuList,
	NavigationMenuTrigger,
	navigationMenuTriggerStyle,
} from '#app/components/ui/navigation-menu.tsx'
import { cn } from '#app/utils/misc.tsx'
import { MobileMenu } from './NavMobileMenu.tsx'

const components: { title: string; href: string; description: string }[] = [
	{
		title: 'España',
		href: '/es/leyes',
		description: 'Consulta las principales leyes españolas.',
	},
]

export default function HeaderNavMenu() {
	return (
		<>
			<div className="hidden flex-1 items-center space-x-4 sm:justify-end md:flex">
				<NavigationMenu>
					<NavigationMenuList>
						<NavigationMenuItem>
							<NavigationMenuTrigger>Administraciones</NavigationMenuTrigger>
							<NavigationMenuContent>
								<ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-1">
									<ListItem href="/es/administraciones" title="España">
										Consulta trámites de las administraciones del Estado, CCAA y
										ayuntamientos.
									</ListItem>
									<ListItem href="/ar/administraciones" title="Argentina">
										Busca en las administraciones del gobierno, provincias y
										municipalidades.
									</ListItem>
									<ListItem href="/mx/administraciones" title="México">
										Consulta trámites del Gobierno Federal, los estados y los
										municipios.
									</ListItem>
								</ul>
							</NavigationMenuContent>
						</NavigationMenuItem>
						<NavigationMenuItem>
							<NavigationMenuTrigger>Legislación</NavigationMenuTrigger>
							<NavigationMenuContent>
								<ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-1 lg:w-[600px] ">
									{components.map(component => (
										<ListItem
											key={component.title}
											title={component.title}
											href={component.href}
										>
											{component.description}
										</ListItem>
									))}
								</ul>
							</NavigationMenuContent>
						</NavigationMenuItem>
						<NavigationMenuItem>
							<Link to="/es/guias">
								<NavigationMenuLink className={navigationMenuTriggerStyle()}>
									Guías legales
								</NavigationMenuLink>
							</Link>
						</NavigationMenuItem>
					</NavigationMenuList>
				</NavigationMenu>
			</div>
			{/* Mobile header */}
			<div className="w-full md:hidden">
				<header className="mt-4 flex items-center justify-between px-4">
					<div className="flex items-center">
						<Link
							to="/"
							aria-label="papelea"
							title="papelea"
							className="items-center space-x-2 md:flex"
						>
							<img
								className="max-h-12 "
								src="/img/brand/logo.png"
								alt="Papelea logo"
							/>

							<span className="ml-3 text-2xl font-bold tracking-wide text-gray-900">
								{' '}
							</span>
						</Link>
					</div>

					<div className="flex pr-4">
						<MobileMenu />
					</div>
				</header>
			</div>
		</>
	)
}

const ListItem = React.forwardRef<
	React.ElementRef<'a'>,
	React.ComponentPropsWithoutRef<'a'>
>(({ className, title, children, ...props }, ref) => {
	return (
		<li>
			<NavigationMenuLink asChild>
				<a
					ref={ref}
					className={cn(
						'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-amber-100 hover:text-accent-foreground focus:bg-amber-100 focus:text-accent-foreground',
						className,
					)}
					{...props}
				>
					<div className="text-sm font-bold leading-none">{title}</div>
					<p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
						{children}
					</p>
				</a>
			</NavigationMenuLink>
		</li>
	)
})
ListItem.displayName = 'ListItem'
