export default function LogoCloud() {
	return (
		<div className="mt-8 bg-amber-50">
			<div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
				<div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
					<div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
						<img
							className="h-10"
							src="/img/media/cinco-dias.png"
							alt="Cinco Días"
						/>
					</div>
					<div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
						<img
							className="h-10"
							src="/img/media/expansion.png"
							alt="Expansión"
						/>
					</div>
					<div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
						<img
							className="h-10"
							src="/img/media/efe-emprende.png"
							alt="EFE Emprende"
						/>
					</div>
					<div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-2">
						<img
							className="h-12"
							src="/img/media/cuatrocasas_acelera.png"
							alt="CuatroCasas Telefonica"
						/>
					</div>
				</div>
				<div className="flex flex-col items-center">
					<h2 className="text-md mx-auto mt-8 max-w-md text-center font-light text-amber-800 lg:max-w-xl ">
						Empresa participante en Cuatrecasas ACELERA en colaboración con
						Telefónica Open Future.
					</h2>
				</div>
			</div>
		</div>
	)
}
