import { Link } from '@remix-run/react'
import { Button } from '#app/components/ui/button.tsx'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from '#app/components/ui/dropdown-menu.tsx'

export function MobileMenu() {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="outline" size="sm">
					Menu
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56">
				<DropdownMenuGroup>
					<DropdownMenuSub>
						<DropdownMenuSubTrigger>Administraciones</DropdownMenuSubTrigger>
						<DropdownMenuPortal>
							<DropdownMenuSubContent>
								<DropdownMenuItem>
									<Link to="/es/administraciones" title="España">
										España
									</Link>
								</DropdownMenuItem>
								<DropdownMenuItem>
									<Link to="/ar/administraciones" title="Argentina">
										Argentina
									</Link>
								</DropdownMenuItem>
								<DropdownMenuItem>
									<Link to="/mx/administraciones" title="EspMéxicoaña">
										México
									</Link>
								</DropdownMenuItem>{' '}
							</DropdownMenuSubContent>
						</DropdownMenuPortal>
					</DropdownMenuSub>
				</DropdownMenuGroup>
				<DropdownMenuItem>
					<Link to="/es/leyes" title="Legislación España">
						Legislación
					</Link>
				</DropdownMenuItem>
				<DropdownMenuItem>
					<Link to="/es/guias" title="Guías legales España">
						Guías legales
					</Link>
				</DropdownMenuItem>{' '}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
